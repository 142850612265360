import { Box, Grid, Slider, Typography, useMediaQuery } from '@mui/material';
import { theme } from '../../../../styles/Theme';
import CustomSwitchWithLabels from '../helpers/SwitchComponent';
import TextInputComponent from '../helpers/TextInputComponent';
import TripleCustomSwitchWithLabels from '../helpers/TripleSwitchComponent';
import React, { useState, useEffect } from 'react';

const classes = {
  wrapper:{

    [theme.breakpoints.down('step7Lg')]: {
      //marginTop:"20px"
    },


  }
  ,
  text_input_wrapper: {
    [theme.breakpoints.down('step7Lg')]: {
      padding: '30px', // increased padding at 1420px breakpoint
    },
  },
  labelWrapper: {
    alignText: 'center',
  },
  switchWrapper: {},
  label: {
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  rowAligment: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    width: '35%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
   
  },
};

const STEP_7_OPTIONAL_CTO_OVERRIDES = ({ formik }) => {
  const mobileMid = useMediaQuery(theme.breakpoints.down('lg'));
  const is1420px = useMediaQuery('(max-width:1420px)'); 
  const is1270px = useMediaQuery('(max-width:1270px)'); 
  const is960px = useMediaQuery('(max-width:960px)');
  const [hideTooltip, setHideTooltip] = React.useState(true);
  return (
     
    <Grid container spacing={mobileMid ? 5 : 7} justifyContent='space-evenly' >
      <Grid
        item
        lg={3.5}
        md={4}
        sm={12}
        xs={12}
        sx={classes.text_input_wrapper}
        style={{ marginTop: is1420px && !is960px ? '35px' : undefined }}>
    
        <TextInputComponent
          
          label={
            <Typography sx={classes.label} align="center">
          Years of experience:
              <Typography component="span" color="textSecondary"> (optional)</Typography>
            </Typography>
          }
          id='ctoyrsexp'
          name='ctoyrsexp'
          placeholder=''
          formik_props={formik}
          flag={false}
        />
      </Grid>
      <Grid
        item
        lg={3.5}
        md={4}
        sm={12}
        xs={12}
        sx={classes.text_input_wrapper}
        style={{ marginTop: is1420px && !is960px ? '35px' : undefined }}>
        <TextInputComponent
           
          label={
            <Typography sx={classes.label} align="center">
          # Prior Tech roles:
              <Typography component="span" color="textSecondary"> (optional)</Typography>
            </Typography>
          }
          id='ctotechroles'
          name='ctotechroles'
          placeholder=''
          formik_props={formik}
          flag={false}
        />
      </Grid>
      <Grid
        item
        lg={3.5}
        md={4}
        sm={12}
        xs={12}
        sx={classes.text_input_wrapper}
        style={{ marginTop: is1420px && !is960px ? '35px' : undefined }}>
        <TextInputComponent
         
        label={
          <Typography sx={classes.label} align="center">
        # Prior Senior roles:
            <Typography component="span" color="textSecondary"> (optional)</Typography>
          </Typography>
        }
          id='ctosenior'
          name='ctosenior'
          placeholder=''
          formik_props={formik}
          flag={false}
        />
      </Grid>
      <Grid
        item
        lg={3.5}
        md={4}
        sm={12}
        xs={12}
        sx={classes.text_input_wrapper}
        style={{ marginTop: is1420px && !is960px ? '35px' : undefined }}>
        <TextInputComponent
         
           label={
            <Typography sx={classes.label} align="center">
          # Top Companies Worked at:
              <Typography component="span" color="textSecondary"> (optional)</Typography>
            </Typography>
          }
          id='ctotopcomp'
          name='ctotopcomp'
          placeholder=''
          formik_props={formik}
          flag={false}
        />
      </Grid>
      <Grid
        item
        lg={3.5}
        md={4}
        sm={12}
        xs={12}
        sx={classes.text_input_wrapper}
        style={{ marginTop: is1420px && !is960px ? '35px' : undefined }}>
        <TextInputComponent
         
          
          label={
            <Typography sx={classes.label} align="center">
           # VC-Backed Companies Worked At:
              <Typography component="span" color="textSecondary"> (optional)</Typography>
            </Typography>
          }
          id='ctovccomp'
          name='ctovccomp'
          placeholder=''
          formik_props={formik}
          flag={false}
        />
      </Grid>
      <Grid
        item
        lg={3.5}
        md={4}
        sm={12}
        xs={12}
        sx={classes.text_input_wrapper}
        style={{ marginTop: is1420px && !is960px ? '35px' : undefined }}>
        <TextInputComponent
         
          label={
            <Typography sx={classes.label} align="center">
            # Senior Roles at VC-Backed Companies:
              <Typography component="span" color="textSecondary"> (optional)</Typography>
            </Typography>
          }
          id='ctoseniorvc'
          name='ctoseniorvc'
          placeholder=''
          formik_props={formik}
          flag={false}
        />
      </Grid>






      <Grid item lg={12} md={12} sm={12} xs={12}
      
    
      >
          <Typography sx={classes.label}>Prior Founder Roles:<Typography component="span" color="textSecondary" > (optional)</Typography></Typography>
            <Box
              display='flex'
              flexDirection='row'
              alignItems='center'
              justifyContent='flex-start'
              width={mobileMid ? '100%' : '25%'}
              sx={{ margin: '0 auto' }}
            >



          <Slider
            id='ctofounder'
            name='ctofounder'
            value={formik.values.ctofounder || 0}
            onChange={(event, value) => {
              formik.handleChange(event);
              setHideTooltip(value === -1);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.ctofounder && formik.errors.ctofounder}
            marks={[
              { value: 0, label: '0' },
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
              { value: 4, label: '4' },
              { value: 5, label: '5' },
            ]}
            max={5}
            min={-1}
            size={mobileMid ? 'small' : 'medium'}
            valueLabelDisplay='auto'
            valueLabelFormat={(value) => value !== -1 ? value : ''}
            sx={{
              '@media (max-width: 1279px)': {
                margin: '0 auto', // center the Slider
              },
              width: mobileMid ? '185px' : undefined,
              borderRadius: 0,
              '& .MuiSlider-rail': {
                height: 10,
                borderRadius: 0,
                color: formik.values.ctofounder === -1 ? 'grey' : '#9A8CFF',
              },
              '& .MuiSlider-track': {
                height: 10,
                borderRadius: 0,
                color: formik.values.ctofounder === -1 ? 'grey' : '#9A8CFF',
              },
              '& .MuiSlider-thumb': {
                color: formik.values.ctofounder === -1 ? 'grey' : '#9A8CFF',
              },
              '& .MuiSlider-valueLabelOpen': {
                display: hideTooltip ? 'none' : 'flex',
              },
            }}
          />


            </Box>
        </Grid>



      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        xl={2.3}
        sx={classes.rowAligment}
      >
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={classes.label}>
                Top-100 Uni? <br/>
                <Typography component="span" color="textSecondary" >(optional)</Typography>
            </Typography>
        </Grid>

          <Grid item>
            <TripleCustomSwitchWithLabels
              id='ctotop100'
              name='ctotop100'
              checked={formik.values.ctotop100}
              onChange={formik.handleChange}
            />
       
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        xl={2.3}
        sx={classes.rowAligment}
      >
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <Grid item >
            <Typography sx={classes.label}>Relevant Degree? <Typography component="span" color="textSecondary" >(optional)</Typography></Typography>
          </Grid>
          <Grid item>
          <TripleCustomSwitchWithLabels
              id='ctodegree'
              name='ctodegree'
              checked={formik.values.ctodegree}
              onChange={formik.handleChange}
            />
            
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        xl={2.3}
        sx={classes.rowAligment}
      >
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <Grid item>
            <Typography sx={classes.label}>MBA? <br/><Typography component="span" color="textSecondary" >(optional)</Typography></Typography>
          </Grid>
          <Grid item>
            <TripleCustomSwitchWithLabels
              id='ctomba'
              name='ctomba'
              checked={formik.values.ctomba}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={3}
        lg={3}
        xl={2.3}
        sx={classes.rowAligment}
      >
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
         
          
        >
          <Grid item  sx={{mt:'-25px'}} >
            <Typography
              sx={classes.label}>Tech Skills (LinkedIn)? <Typography component="span" color="textSecondary" >(optional)</Typography> </Typography>
          </Grid>
          <Grid item>
            <TripleCustomSwitchWithLabels
              id='ctotechskills'
              name='ctotechskills'
              checked={formik.values.ctotechskills}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default STEP_7_OPTIONAL_CTO_OVERRIDES;
