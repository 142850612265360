import {
  Alert,
  Button,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { theme } from '../../styles/Theme';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import mainLogo from '../../assets/e1logo.svg';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ArrowBack } from '@mui/icons-material';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import Result from './components/Result';
import affinity_logo from '../../assets/affinity_logo.png';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
const classes = {
  page: {
    width: '100%',
    overflow: 'auto',
  },

  bold: {
    fontWeight: 'bold',
  },
  textAlign: {
    display: 'flex',
    justifyContent: 'center',
  },
  logoWrapper: {
    paddingLeft: '2%',
    paddingRight: '2%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  loadingAnimationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialog: {
    [theme.breakpoints.down('sm')]: {
      borderRadius: '10px 10px 0 0',
      height: 'min-content',
      alignSelf: 'end',
    },
    padding: '15px',
  },
  form_wrapper: {
    width: '50%',
    mb: '15px',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      margin: '0 auto',
   
    },
    overflowY: 'auto',
  },

  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentInside: {
    maxWidth: '420px',
    width: '100%', 
},

};

const ResultsPage = () => {
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const mobileMid = useMediaQuery(theme.breakpoints.down('sm'));
  let navigate = useNavigate();
  const [affinityModal, setAffinityModal] = useState(false);
  const [modalTextInput, setModalTextInput] = useState('');
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const { user_id } = useParams();
  const client_id = Cookies.get('access_token');
  const [error, setError] = useState(null);
  const [warningError, setWarningError] = useState(false);
  const [affinityError, setAffinityError] = useState(null);



  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(user_id);

        const response = await axios.get(`/api/results/${user_id}`);
        console.log(response);

     
        if (response.status === 202) {
          return;
        }
        console.log(response.data)
        setUserData(response.data);

        if (response.data.warning) {
         
          setWarningError(response.data.warning);
          setTimeout(() => {
            setWarningError(null);
          }, 10000);
        }

        setLoading(false);
        clearInterval(intervalId); // Stop polling once data is received.
      } catch (error) {
        setError(error);
        setTimeout(() => {
          setError(null);
        }, 5000);
        setLoading(false);
      }
    };

  

    fetchData();
    const intervalId = setInterval(fetchData, 15000); // Polling every 15 seconds

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [user_id]);


  const handleBack = async () => {

    const response = await axios.get(`/api/delete/${client_id}`);
    window.location.href = '/';
  };

  const logoutResults = () => {
    console.log("LOG OUT");
    axios
      .post('/api/logout', {
        headers: {
          Authorization: ``,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        Cookies.remove('access_token');
        window.location.href = '/';

      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        style={userData ? {height: '100vh' } : {}} 
       // sx={{ ...classes.page, height: '100vh' }} 
      sx={classes.page}
      >
        <Grid item xs={12} sx={classes.logoWrapper}>
          <img
            src={mainLogo}
            alt='logo'
            style={{
              width: mobile ? '5rem' : '15rem',
              height: mobile ? '3rem' : '10rem',
              fill: 'white',
            }}
          />
          <Button
            variant='outlined'
            sx={{ border: '1px solid white', color: 'white' }}
            onClick={() => logoutResults()}
            startIcon={<LogoutOutlinedIcon sx={{ color: 'white' }} />}
          >
            Logout
          </Button>
        </Grid>
        <Grid item sx={{ width: '100%', ml: '2%', alignItems: 'start' }}>
          <Button
            startIcon={<ArrowBack />}
            onClick={() => handleBack()}
            sx={{ color: 'white' }}
          >
            Score another company
          </Button>
        </Grid>
        {warningError && (
    <Alert
        variant='filled'
        severity='warning'
        sx={{
            position: 'fixed',
            top: 10,
            left: 0,
            right: 0,
            zIndex: 9999,
            margin: '0 auto',
            width: '25%',
            borderRadius: '20px',
            backgroundColor: '#FEDD5',
            color: '#FEDD5',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center' 
        }}
    >
        <Typography>{warningError[0]}</Typography>
    </Alert>
)}
        {affinityError && (
          <Alert
            variant='filled'
            severity='warning'
            sx={{
              position: 'fixed',
              top: 10,
              left: 0,
              right: 0,
              zIndex: 9999,
              margin: '0 auto',
              width: '30%',
              borderRadius: '20px',
              backgroundColor: '#FEDD5',
              color: '#FEDD5',
            }}
          >
            <Typography>{affinityError}</Typography>
          </Alert>
        )}
        {loading ? (
          <Grid
            item
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '60px',
            }}
          >
            
            <CircularProgress sx={{ color: 'white' }} />
          </Grid>
        ) : error ? (
          <Alert
            variant='filled'
            severity='warning'
            sx={{
              position: 'fixed',
              top: 10,
              left: 0,
              right: 0,
              zIndex: 9999,
              margin: '0 auto',
              width: '30%',
              borderRadius: '20px',
              backgroundColor: '#FEDD5',
              color: '#FEDD5',
            }}
          >
            <Typography>
              Error while fetching data. Please, try again.
            </Typography>
          </Alert>
        ) : (
          <>
{warningError && (
    <Alert
        variant='filled'
        severity='warning'
        sx={{
            position: 'fixed',
            top: 10,
            left: 0,
            right: 0,
            zIndex: 9999,
            margin: '0 auto',
            width: '25%',
            borderRadius: '20px',
            backgroundColor: '#FEDD5',
            color: '#FEDD5',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}
    >
        <Typography>{warningError[0]}</Typography>
    </Alert>
)}

<Grid 
  item 
  style={userData!=null ? { marginBottom: '150px' } : {}} 
  sx={classes.form_wrapper}
>
  {userData ? (
    <Result result={userData} />
  ) : (
    <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
      <Typography variant='h5' sx={{ color: 'white' }}>
        No Data Fetched
      </Typography>
    </Grid>
  )}
</Grid>



  
          </>
        )}
      </Grid>
   
    
    
    </>
  );
};

export default ResultsPage;
